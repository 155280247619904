<template>
  <div>
    <el-dialog
      v-elDragDialog
      width="700px"
      :title="isCreated ? $t('otaList.addProject') : $t('otaList.editProject')"
      :close-on-click-modal="false"
      :visible.sync="show"
      class="add-app"
    >
      <el-form
        v-loading="loading"
        :model="form"
        :rules="rules"
        size="small"
        ref="form"
        label-width="100px"
      >
        <el-form-item :label="$t('otaList.projectName')" prop="projectName">
          <el-input v-model.trim="form.projectName" :placeholder="$t('otaList.enterProjectName')" />
        </el-form-item>

        <el-form-item :label="$t('otaList.platform')" prop="platform">
          <el-select v-model="form.platform" :placeholder="$t('otaList.selectPlatform')">
            <el-option v-for="item in platformList" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('deviceRoom.operatingSystem')" prop="operatingSystem">
          <el-select
            v-model="form.operatingSystem"
            :placeholder="$t('deviceRoom.selectOperatingSystem')"
          >
            <el-option
              v-for="item in systemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('deviceList.deviceType')" prop="modelId">
          <el-select v-model="form.modelId" :placeholder="$t('deviceModal.selectDeviceModel')">
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('otaList.projectDesc')" prop="projectDescription">
          <el-input
            type="textarea"
            rows="4"
            resize="none"
            v-model="form.projectDescription"
            :placeholder="$t('otaList.enterProjectDesc')"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">{{ $t('cancel') }}</el-button>
        <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
          $t('save')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['modelList'],
  data() {
    const rules = {
      projectName: [{ required: true, message: this.$t('otaList.enterProjectName') }],
      platform: [
        { required: true, trigger: 'change', message: this.$t('otaList.pleaseSelectPlatform') }
      ],
      operatingSystem: [
        { required: true, trigger: 'change', message: this.$t('otaList.pleaseSelectSystem') }
      ],
      modelId: [
        { required: true, trigger: 'change', message: this.$t('deviceModal.pSelectDeviceModel') }
      ],
      projectDescription: [{ required: true, message: this.$t('otaList.enterProjectDesc') }]
    }

    return {
      loading: false,
      systemList: [
        {
          label: 'Android',
          value: 1
        },
        {
          label: 'Linux',
          value: 2
        },
        {
          label: 'QNX',
          value: 3
        },
        {
          label: 'RTOS',
          value: 4
        },
        {
          label: 'Windows',
          value: 5
        }
      ],
      platformList: [],
      form: {
        projectName: '',
        platform: null,
        operatingSystem: null,
        modelId: null,
        projectDescription: ''
      },
      rules
    }
  },
  created() {
    if (this.currentItem) {
      Object.keys(this.form).forEach(key => {
        if (key === 'modelId') {
          const targetModel = this.modelList.find(_ => _.label === this.currentItem.modelName)
          this.form[key] = (targetModel && targetModel.value) || null
        } else {
          this.form[key] = this.currentItem[key] || ''
        }
      })
    }
    this.getOtaPlatform()
  },
  methods: {
    async getOtaPlatform() {
      let { result = '' } = await this.$api.deviceManagement.getOtaPlatform()
      this.platformList = result ? result.split(',') : []
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const sendData = this.form

          try {
            if (this.currentItem) {
              sendData.projectId = this.currentItem.projectId
              await this.$api.otaManagement.projectUpdate(sendData)
              this.$message.success(this.$t('editSuccess'))
            } else {
              await this.$api.otaManagement.projectAdd(sendData)
              this.$message.success(this.$t('addSuccess'))
            }

            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .el-select {
  width: 100%;
}
.avatar-uploader .el-upload:hover {
  border-color: #ef2b23;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.flex {
  display: flex;
  align-items: center;

  .tips {
    line-height: 20px;
    margin-left: 20px;
  }
}
</style>
