<template>
  <page class="publishOta-warp" :page-title="$t('publishOta.publishProject')">
    <div class="stepHeader">
      <el-steps :active="active" align-center finish-status="success">
        <el-step :title="$t('otaList.selectProject')" />
        <el-step :title="$t('publishOta.configurePolicy')" />
        <el-step :title="$t('publishApp.complete')" />
      </el-steps>
    </div>

    <el-form :model="form" ref="form" :rules="rules" label-width="150px" v-loading="loading">
      <div v-show="active === 0">
        <el-form-item :label="$t('publishApp.forceUpdates')" prop="forcedUpdateType">
          <el-radio-group v-model="form.forcedUpdateType">
            <el-radio :label="1">{{ $t('yes') }}</el-radio>
            <el-radio :label="0">{{ $t('no') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="is-required"
          :label="$t('publishOta.selectItemsPublish')"
          prop="projectInfos"
        >
          <div class="select" @click="selectAppVisible = true">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>{{ $t('publishApp.selectListApps') }}</span>
          </div>
          <div class="itemInfo" v-for="(item, index) in form.projectInfos" :key="item.id">
            <i class="el-icon-close" @click="delHandler(index, item.projectId)"></i>
            <div class="name">{{ item.projectName }}</div>
            <div class="commonItem">
              <span class="left">{{ $t('deviceList.deviceType') }}：</span>
              <span class="right">
                {{ item.modelName }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{ $t('otaList.platform') }}：</span>
              <span class="right">
                {{ platformStr[item.platform] || '-' }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{ $t('deviceRoom.operatingSystem') }}：</span>
              <span class="right">
                {{ systemStr[item.operatingSystem] || '-' }}
              </span>
            </div>
            <div class="commonItem">
              <span class="left">{{ $t('otaList.equipmentNumber') }}：</span>
              <span class="right">
                {{ item.deviceNumber }}
              </span>
            </div>
          </div>
        </el-form-item>
      </div>

      <div v-show="active === 1">
        <el-form-item class="is-required" :label="$t('publishOta.upgradeMethod')">
          <el-radio-group v-model="upgradeType">
            <el-radio v-for="item in upgradeItems" :key="item.key" :label="item.label">
              {{ item.title }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-show="upgradeType === 1"
          :label="$t('publishOta.sourceVersion')"
          prop="sourceVersionId"
        >
          <el-select v-model="form.sourceVersionId" :placeholder="$t('publishOta.selectVersion')">
            <el-option
              v-for="item in sourceVersionItems"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-show="upgradeType === 2" :label="$t('publishOta.enterSingleSN')" prop="sn">
          <el-input v-model="form.sn" />
        </el-form-item>
        <el-form-item v-show="upgradeType !== 3" :label="$t('publishOta.pushContent')">
          <el-input v-model="form.pushContent" />
        </el-form-item>

        <el-form-item v-show="upgradeType === 3" :label="$t('publishOta.batchAddSN')" prop="snFile">
          <el-upload
            drag
            :on-change="onchange"
            :show-file-list="true"
            :multiple="false"
            :auto-upload="false"
            accept=".csv, .xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref="upload"
            name="file"
            action="null"
          >
            <div @click="$refs.upload.clearFiles()">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{ $t('deviceModal.dropFilesHere') }}，{{ $t('deviceModal.or') }}
                <em>{{ $t('deviceModal.clickUpload') }}</em>
              </div>
            </div>
          </el-upload>

          <p style="color:#adadad;margin-top:10px;">{{ $t('publishOta.fileFormatTips') }}</p>
          <el-button type="primary" size="default" @click="downLoad">{{
            $t('publishOta.downloadTemplate')
          }}</el-button>
        </el-form-item>

        <el-form-item
          class="is-required"
          v-show="upgradeType === 1"
          :label="$t('deviceModal.selectCustomer')"
          prop="spId"
        >
          <div class="group">
            <div class="g-header">
              <el-button
                :disabled="!form.sourceVersionId"
                type="primary"
                @click="serviceVisible = true"
                >{{ $t('deviceModal.selectCustomer') }}</el-button
              >

              <div class="r">
                <div class="info">
                  <span class="label"
                    >{{ $t('publishApp.selected') }}：{{ total }}{{ $t('adminHomePage.tower')
                    }}{{ $t('deviceModal.devices') }}</span
                  >
                </div>
                <el-link
                  type="danger"
                  :underline="false"
                  icon="el-icon-delete"
                  @click="selectData = []"
                  >{{ $t('publishApp.clearAll') }}</el-link
                >
              </div>
            </div>
            <div class="content">
              <template>
                <p class="no-data" v-if="!selectData.length">
                  {{ $t('publishOta.noCustomerSelected') }}
                </p>
                <template v-else>
                  <div class="item" v-for="(item, index) in selectData" :key="item.id + index">
                    <div class="item-left">
                      <span class="name">{{ item.name }}</span>
                      <span class="num"
                        >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                        {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                      >
                    </div>
                    <div class="item-right" @click="selectData.splice(index, 1)">
                      x
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </el-form-item>
      </div>

      <el-form-item v-show="active === 2" :label="$t('publishOta.ItemsPublish')">
        <div class="itemInfo" v-for="item in form.projectInfos" :key="item.id">
          <div class="name">{{ item.projectName }}</div>
          <div class="commonItem">
            <span class="left">{{ $t('deviceList.deviceType') }}：</span>
            <span class="right">
              {{ item.modelName }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.platform') }}：</span>
            <span class="right">
              {{ platformStr[item.platform] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('deviceRoom.operatingSystem') }}：</span>
            <span class="right">
              {{ systemStr[item.operatingSystem] || '-' }}
            </span>
          </div>
          <div class="commonItem">
            <span class="left">{{ $t('otaList.equipmentNumber') }}：</span>
            <span class="right">
              {{ item.deviceNumber }}
            </span>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-show="active === 2" :label="$t('publishApp.publishingMethod')">
        <span style="margin-right:10px">{{ currentWay }}</span>
        <span v-if="this.currentWay !== $t('publishOta.batchAddSNNumber')">
          {{ $t('adminHomePage.common') }} {{ total || 0 }} {{ $t('adminHomePage.tower')
          }}{{ $t('deviceModal.devices') }}</span
        >
      </el-form-item>
    </el-form>

    <div class="footer">
      <el-button v-if="active !== 0 && active !== 3" type="primary" @click="pre">{{
        $t('publishApp.preStep')
      }}</el-button>
      <el-button
        v-if="active <= 2"
        type="primary"
        :disabled="active === 0 && !form.projectInfos.length"
        @click="next"
        >{{ active === 2 ? $t('publishApp.publishNow') : $t('publishApp.nextStep') }}</el-button
      >

      <el-button v-if="active === 3" @click="active = 0">{{ $t('publishApp.complete') }}</el-button>
    </div>

    <select-app-modal
      v-if="selectAppVisible"
      :visible.sync="selectAppVisible"
      @selectApp="renderData"
    />

    <service-modal
      v-if="serviceVisible"
      :visible.sync="serviceVisible"
      :select-data.sync="selectData"
      :versionId="form.sourceVersionId"
    />
  </page>
</template>

<script>
import ServiceModal from '../components/ServiceModal'
import SelectAppModal from '../components/SelectAppModal'
export default {
  props: {},
  components: {
    ServiceModal,
    SelectAppModal
  },
  data() {
    const types = [
      {
        value: 5,
        label: this.$t('publishApp.byCustomer')
      },
      {
        value: 6,
        label: this.$t('publishApp.fullRelease')
      }
    ]

    const rules1 = {
      sourceVersionId: [{ required: true, message: this.$t('publishOta.selectSourceVersion') }],
      type: [{ required: true, message: this.$t('publishOta.selectPublishingMethod') }],
      spId: [{ required: true, message: this.$t('serviceAccountManagement.pleaseSelectCustomer') }]
    }
    const rules2 = {
      sn: [{ required: true, message: this.$t('deviceModal.pleaseEnterSNNumber') }]
    }

    const rules3 = {
      snFile: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }]
    }
    return {
      active: 0,
      upgradeType: 1,
      currentWay: '',
      targetItem: {},
      bapingIsSelected: false,
      systemStr: {
        1: 'Android',
        2: 'Linux',
        3: 'QNX',
        4: 'RTOS',
        5: 'Windows'
      },
      platformStr: {
        1: 'RK3288',
        2: 'RK3288_5.1',
        3: 'RK3399_7.1'
      },
      form: {
        projectInfos: [],
        sourceVersionId: '',
        versionId: '',
        pushContent: '',
        sn: '',
        snFile: '',
        spId: '',
        forcedUpdateType: 0
      },
      types,
      rules: {},
      rules1,
      rules2,
      rules3,
      selectData: [],
      sourceVersionItems: [],
      projectList: [],
      serviceVisible: false,
      selectAppVisible: false,
      loading: false,
      deviceTotal: 0,
      upgradeItems: [
        {
          key: 1,
          label: 1,
          title: this.$t('publishOta.sourceVersion')
        },
        {
          key: 2,
          label: 2,
          title: this.$t('publishOta.addSingleSNNumber')
        },
        {
          key: 3,
          label: 3,
          title: this.$t('publishOta.batchAddSNNumber')
        }
      ]
    }
  },
  computed: {
    total() {
      if (this.currentWay == this.$t('publishOta.addSingleSNNumber')) {
        return 1
      }

      return this.selectData.reduce((a, b) => {
        return a + (b.deviceTotal || 0)
      }, 0)
    }
  },
  created() {
    this.rules = { ...this.rules1 }
  },
  watch: {
    upgradeType(val) {
      if (val === 1) {
        this.rules = { ...this.rules1 }
      } else if (val === 2) {
        this.rules = { ...this.rules2 }
      } else {
        this.rules = { ...this.rules3 }
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })

      this.currentWay = this.upgradeItems.find(_ => _.label === val).title
    },
    selectData(val) {
      this.form.spId = val.map(item => item.id).join(',')
    }
  },
  methods: {
    async downLoad() {
      this.loading = true
      try {
        const res = await this.$api.deviceManagement.deviceDownload({ type: 3 })

        window.open(res.result, '_self')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getListVersionByProjectId() {
      try {
        const res = await this.$api.otaManagement.listVersionByProjectId({
          projectId: this.form.projectInfos[0].projectId
        })
        let item = {}

        if (res.result) {
          this.sourceVersionItems = res.result.map(item => ({
            label: item.versionName,
            value: item.versionId
          }))
        }
      } catch (err) {
        console.log(err)
      }
    },
    onchange(file) {
      this.form.snFile = file.raw
    },
    renderData(val) {
      console.log(val)
      const { checks, children, ...obj } = val.project
      this.form.versionId = val.versionId
      this.form.sourceVersionId = val.versionId
      this.form.projectInfos = [obj]
    },
    clear() {
      this.upgradeType = 1
      this.currentWay = ''
      this.form.sn = ''
      this.form.snFile = ''
      this.form.pushContent = ''
      this.form.sourceVersionId = ''
      this.form.projectInfos = []
      this.form.spId = ''
      this.selectData = []
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    pre() {
      if (this.active === 1) {
        this.clear()
      }

      if (this.active-- < 0) this.active = 0
    },
    async next() {
      if (this.active === 2) {
        try {
          await this.submit()
        } catch (e) {
          console.log(e)
        }
      } else {
        if (this.active === 0) {
          this.getListVersionByProjectId()
          this.active++
          return
        }

        this.$refs.form.validate(valid => {
          if (valid) {
            this.active++
          }
        })
      }
    },
    delHandler(index, projectId) {
      this.targetItem = {}
      this.form.projectInfos.splice(index, 1)
    },
    async submit() {
      const {
        pushContent,
        spId,
        sn,
        versionId,
        sourceVersionId,
        projectInfos,
        snFile,
        forcedUpdateType
      } = this.form

      try {
        this.loading = true
        const formData = new FormData()
        const sendData = {
          publishWay: this.upgradeType,
          projectName: projectInfos[0].projectName,
          sourceVersionId,
          versionId,
          pushContent,
          spId,
          forcedUpdateType
        }

        if (sn) {
          sendData.snList = [sn]
        }

        if (snFile) {
          sendData.snFile = snFile
        }

        if (this.upgradeType === 3) {
          formData.append('publishWay', this.upgradeType)
          formData.append('snFile', snFile)
          formData.append('versionId', versionId)
        } else {
          Object.entries(sendData).forEach(([key, value]) => {
            formData.append(key, value)
          })
        }

        const res = await this.$api.otaManagement.versionPublish(formData)

        this.$message({
          message: this.$t('publishApp.publishSuccess'),
          type: 'success',
          duration: 5000
        })

        this.active = 0

        this.clear()
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.stepHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.el-steps {
  width: 80%;
  ::v-deep .el-step__title {
    font-size: 12px;
  }
}
.publishOta-warp {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 8px;
}

::v-deep .el-upload {
  width: 100%;
  .el-upload-dragger {
    width: 100%;
  }
}

.select {
  width: 100%;
  margin-bottom: 10px;
  height: 175px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #d9d9d9;
  &:hover {
    border-color: #ef2b23;
  }
  .el-icon-plus {
    font-size: 80px;
    color: #ccc;
  }
  span {
    font-size: 12px;
  }
}

.container-wrap {
  .baping-active {
    color: #fff !important;
    background-color: #ef2b23 !important;
    border-color: #ef2b23 !important;
  }

  .baping-tag {
    color: #fff;
    background-color: #ef2b23;
    width: 34px;
    height: 40px;
    position: absolute;
    top: 0;
    line-height: 20px;
    left: 12px;
    text-align: center;
    font-size: 12px;
    padding: 1px;
    border-radius: 0 0 2px 2px;
  }

  .itemInfo {
    margin-top: 16px;
  }
  .footer {
    text-align: center;
    margin-bottom: 10px;
  }
  .group {
    .g-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      align-items: center;
      .r {
        display: flex;
        .info {
          margin-right: 10px;
        }
      }
    }
    .content {
      min-height: 200px;
      overflow: auto;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      &.hide {
        display: none;
      }
    }
    .content {
      position: relative;
      padding: 10px;
      .item {
        display: inline-block;
        overflow: hidden;
        padding: 8px;
        justify-content: space-between;
        border: 1px solid #ccc;
        line-height: 20px;
        align-items: center;
        width: 150px;
        height: 120px;
        position: relative;
        margin-right: 10px;
        .item-left {
          display: flex;
          float: left;
          flex-direction: column;
          .name {
            word-break: break-word;
            font-size: 14px;
          }
          .num {
            font-size: 12px;
            color: #666;
          }
        }
        .item-right {
          font-size: 20px;
          float: right;
          margin-top: 10px;
          cursor: pointer;
          width: 0;
          height: 0;
          border-top: 20px solid #f56c6c;
          border-left: 20px solid transparent;
          position: absolute;
          right: 0;
          margin: 0;
          top: 0;
          &::after {
            content: 'x';
            position: absolute;
            left: -10px;
            font-size: 12px;
            color: #fff;
            top: -25px;
          }
        }
      }

      .sn {
        width: 250px;
        height: 20px;
      }
    }
  }
}
</style>
